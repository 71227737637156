import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDMContentTabChildNodes } from "../../redux/actions/DataMapContentTabAction";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import LevelComponent from "./levelComponent";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EnhancedTable from "./alertsTable";
import { headCellsForSqlStatements } from "../common/headCells";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import LoaderComponent from "../shared/LoaderComponent";
import CloseIcon from "../../assets/vizr_images/ic_remove.svg";
import { getCubejsApi, redirectToLogin } from "../common/Dashboard/cubejs-util";
import { isCloudFileSystem } from "../common/utilities";
import "../../assets/scss/dataMapContent.scss";
import page_loader from "../../assets/vizr_images/page_loader.gif";
import { 
  CLEAR_ALL_FIELDS_DATA, 
  CLEAR_SENSITIVE_DATA_FIELDS, 
  dataMapsplitView, 
  ONEDRIVE, 
  SET_LOADER, 
  SET_SOURCE_NAME, 
  FETCH_ALL_FIELDS_DATA_REQUEST,
  FETCH_SENSITIVE_DATA_FIELDS_REQUEST, 
  CLEAR_INSPECT_FILTER_DATA,
  ALL_FILES_LIST,
  GOOGLEDRIVE,
  DROPBOX,
  NTFS,
 } from "../../redux/constants/constants";
import { getSpanDbStatements } from "../../controllers/spandbstatements";
import { isEmpty, isNull } from "lodash";
import Avatar from '@material-ui/core/Avatar';
import onedrivefolder from "../../assets/icons/onedrivefolder.jpg";
import dropboxfolder from "../../assets/icons/dropboxfolder.png";
import onedriveweb from "../../assets/icons/onedriveweb.jpg";
import dropBoxSite from "../../assets/icons/dropBoxSite.png";
import RenderTabsInfo from "./RenderTabsInfo";
import InspectContentTable from "./InspectContentTable";
import InspectFilter from "./InspectFilter";
import InspectViewSwitch from "./InspectViewSwitch";
import HeatMap from "../views/HeatMap/HeatMap";
import googleFolderIcon from "../../assets/icons/googleDriveFolderIcon.jpg";
import UserFolders from "./UserFolders";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useSelectStyles = makeStyles((theme) => ({
  selected: {},
  rootMenuItem: {
    "&$selected": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));

export default function DataMapContents(props) {
  const history = useHistory();
  let { bottomTrayEnable, selectedNodeSubType, sourceName } = props;
  const initGraphNodes = {
    apiSuccess: false,
    data: [],
  };
  const cubejsApi = getCubejsApi();

  const cloudFileSystem = isCloudFileSystem(selectedNodeSubType) || selectedNodeSubType === NTFS;

  const unsuccessfulSave = useSelector(
    (state) => state.sensitivityUpdate.unsuccessful
  );
  const dateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );
  const editSensitivityChangeMessage = useSelector(
    (state) => state.sensitivityUpdate.successMessage
  );
  const successUpdated = useSelector(
    (state) => state.sensitivityUpdate.sensitivityFieldsUpdated
  );
  const sensitiveDataFields = useSelector(
    (state) => state.InspectContentReducer.sensitiveDataFields
  );
  const totalFilesCount = useSelector(
    (state) => state.InspectContentReducer.totalFileCount
  );
  const totalPublicFileCount = useSelector(
    (state) => state.InspectContentReducer.totalPublicFileCount
  );
  const publicSensitiveFieldsData = useSelector(
    (state) => state.InspectContentReducer.publicSensitiveFieldsData
  );
  const totalSensitivePublicFileCount = useSelector(
    (state) => state.InspectContentReducer.totalPublicSensitiveFileCount
  );
  const publicFieldsData = useSelector(
    (state) => state.InspectContentReducer.publicFieldsData
  );
  //Total file count for table at SensitiveFields
  const sensitiveTotalCount = useSelector(
    (state) => state.InspectContentReducer.sensitiveTotalCount
  )
  const allFieldsData = useSelector(
    (state) => state.InspectContentReducer.allFieldsData
  );
  const loader = useSelector(
    (state) => state.InspectContentReducer.loader
  );
  const currentPage = useSelector(
    (state) => state.InspectContentReducer.currentPage
  );
  const pageSize = useSelector(
    (state) => state.InspectContentReducer.pageSize
  );
  const filters = useSelector(
    (state) => state.InspectContentReducer.filters
  );


  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [allTablesData, setAllTablesData] = useState(initGraphNodes);
  const [spanDBStatements, setSpanDBStatements] = useState(initGraphNodes);
  const [databaseManagementFields, setDatabaseManagementFields] =
    useState(initGraphNodes);
  const [allFieldsConsolidated, setAllFieldsConsolidated] = useState([]);
  const [selected, setSelected] = useState([]);
  const [viewTab, setViewTab] = useState("tableView");
  const [initialSensitiveData, setInitialSensitiveData] = useState([]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedFieldForSeverity, setSelectedFieldForSeverity] = useState("");
  const [snackPack, setSnackPack] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [loaderStatus, setLoaderStatus] = useState("");
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [severityPopUpOpen, setSeverityPopUpOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [selectFieldInfo, setSelectInfo] = useState(null);
  const menuClasses = useSelectStyles();
  const permissions = useSelector((state) => state.applicationData.permissions);
  const selectedNode = useSelector(
    (state) => state.selectedNode.selectedNodeId
  );
  const nodesResponseData = useSelector(
    (state) => state.InspectContentReducer.nodesResponseData
  );
  const [views, setViews] = useState(dataMapsplitView);
  const [togglers, setTogglers] = useState([
    { name: "Direct Data Access Statements", state: "active" },
    { name: "Service Data Access Statements", state: "inactive" },
  ]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (anchorEl) {
      setSeverityPopUpOpen(Boolean(anchorEl));
    } else if (anchorEl === null) {
      setSeverityPopUpOpen(Boolean(anchorEl));
    }
  }, [anchorEl]);
  useEffect(() => {
    dispatch({ type: SET_SOURCE_NAME, payload: sourceName });
  }, [])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "CLOSE_SNACKBAR" });
    setOpen(false);
  };

  const handleExited = () => {
    dispatch({ type: "CLOSE_SNACKBAR" });
    setMessageInfo(undefined);
  };

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  useEffect(() => {
    if (editSensitivityChangeMessage !== "") {
      setSnackBarMessage(editSensitivityChangeMessage);
      setValue(0);
      setSelected([]);
    } else if (unsuccessfulSave !== "") {
      setSnackBarMessage(unsuccessfulSave);
      setSelected([]);
    }
  }, [editSensitivityChangeMessage, unsuccessfulSave]);

  useEffect(() => {
    //! To show the default tab in inspect content
    if (selectedNode || value !== 0) {
      setValue(0);
    }
  }, [selectedNode]);

  useEffect(() => {
    if (editSensitivityChangeMessage || unsuccessfulSave) {
      setOpen(true);
    }
  }, [editSensitivityChangeMessage, unsuccessfulSave]);


  useEffect(() => {
    return () => {
      const currentLocation = window.location.pathname;
      if (currentLocation !== ALL_FILES_LIST || selectedNode === "") {
        dispatch({type: CLEAR_INSPECT_FILTER_DATA});
      }
    }
  },[selectedNode, value]);

  // To trigger API based on the current tab.
  const setFieldsData = () => {
    if (value === 0) {
      dispatch({ type: FETCH_SENSITIVE_DATA_FIELDS_REQUEST });
    } else if (value === 1) {
      dispatch({ type: FETCH_ALL_FIELDS_DATA_REQUEST });
    }
  }

  const clearFieldsData = () => {
    if (value === 0) {
      dispatch({ type: CLEAR_SENSITIVE_DATA_FIELDS });
    } else if (value === 1) {
      dispatch({ type: CLEAR_ALL_FIELDS_DATA });
    }
  }

  useEffect(() => {
    if (selectedNode !== "") {
      dispatch({
        type: SET_LOADER,
        payload: true
      });
      setFieldsData();
    } else {
      clearFieldsData(); 
    }
    return () => {
      clearFieldsData();
    };
  }, [selectedNode, currentPage, pageSize, value]);

  const getNodesResponse = async (nodeQuery, queryType) => {
    let nodesResponse = nodesResponseData;
    if (!nodesResponseData) {
      nodesResponse = await fetchDMContentTabChildNodes(nodeQuery, queryType);
    }
    return nodesResponse;
  };
  async function getAllTablesDataApiCall() {
    let responseObj = {
      apiSuccess: false,
      data: [],
    };
    let nodes = [];
    const queryType = "multi";
    const nodeQuery = {
      dimensions: ["Nodes.nodeId"],
      timeDimensions: [],
      order: {},
      filters: [],
    };
    if (selectedNode) {
      nodeQuery.filters.push({
        member: "Nodes.parentId",
        operator: "equals",
        values: [selectedNode],
      });
    }
    const nodesResponse = await getNodesResponse(nodeQuery, queryType);
    if (nodesResponse) {
      nodes.push(selectedNode);
      nodesResponse.length &&
        nodesResponse.map((each) => {
          nodes.push(each[`Nodes.nodeId`]);
        });
      const queryString = {
        dimensions: ["Contents.tableName", "Contents.tableType"],
        filters: [
          {
            member: "Contents.nodeId",
            operator: "equals",
            values: nodes,
          },
        ],
      };
      let response;
      try {
        const resultSet = await cubejsApi.load(queryString);
        response = resultSet.rawData();
      } catch (error) {
        redirectToLogin(error);
      }
      if (response.length) {
        let contentsData = [];
        response.forEach((e) => {
          if (!isNull(e["Contents.tableName"])) {
            contentsData.push(e);
          }
        });
        responseObj.apiSuccess = true;
        responseObj.data = contentsData;
        setAllTablesData(responseObj);
      } else {
        responseObj.apiSuccess = true;
        setAllTablesData(responseObj);
      }
    } else {
      responseObj.apiSuccess = true;
      setAllTablesData(responseObj);
    }
  }

  useEffect(() => {
    if (selectedNode !== "") {
      getAllTablesDataApiCall();
      getSpanDbStatements(
        selectedNode,
        cubejsApi,
        redirectToLogin,
        setSpanDBStatements,
        getNodesResponse
      );
    } else {
      let responseObj = {
        apiSuccess: false,
        data: [],
      };
      setAllTablesData(responseObj);
      setSpanDBStatements(responseObj);
    }
    return () => {
      let responseObj = {
        apiSuccess: false,
        data: [],
      };
      setAllTablesData(responseObj);
    };
  }, [selectedNode]);

  async function getDatabaseManagementFieldsApiCall() {
    let responseObj = {
      apiSuccess: false,
      data: [],
    };
    let nodes = [];
    const queryType = "multi";
    const nodeQuery = {
      dimensions: ["Nodes.nodeId"],
      timeDimensions: [],
      order: {},
      filters: [],
    };
    if (selectedNode) {
      nodeQuery.filters.push({
        member: "Nodes.parentId",
        operator: "equals",
        values: [selectedNode],
      });
    }
    const nodesResponse = await getNodesResponse(nodeQuery, queryType);
    if (nodesResponse) {
      nodes.push(selectedNode);
      nodesResponse.length &&
        nodesResponse.map((each) => {
          nodes.push(each[`Nodes.nodeId`]);
        });
      const queryString = {
        dimensions: ["DBStatements.sqlStatement"],
        filters: [
          {
            member: "DBStatements.nodeId",
            operator: "equals",
            values: nodes,
          },
        ],
      };

      // query DBStatement based on global time range to avoid timeout
      if (dateTimeRange) {
        const dateJson = JSON.parse(dateTimeRange);
        if (dateJson.startDate && dateJson.endDate) {
          const startTime = new Date(dateJson.startDate).getTime(); // dateFormatter(startDate);
          const endTime = new Date(dateJson.endDate).getTime(); // dateFormatter(endDate);
          queryString.filters.push({
            member: "DBStatements.creationTimeStamp",
            operator: "gte",
            values: [`${startTime}`],
          });
          queryString.filters.push({
            member: "DBStatements.creationTimeStamp",
            operator: "lte",
            values: [`${endTime}`],
          });
        }
      }
      let response;
      try {
        const resultSet = await cubejsApi.load(queryString);
        response = resultSet.rawData();
        setLoaderStatus(false);
      } catch (error) {
        redirectToLogin(error);
      }
      if (response.length) {
        responseObj.apiSuccess = true;
        responseObj.data = response;
        setDatabaseManagementFields(responseObj);
      } else {
        responseObj.apiSuccess = true;
        setDatabaseManagementFields(responseObj);
      }
    } else {
      responseObj.apiSuccess = true;
      setDatabaseManagementFields(responseObj);
    }
  }

  useEffect(() => {
    if (selectedNode !== "") {
      setLoaderStatus(true);
      getDatabaseManagementFieldsApiCall();
    } else {
      let responseObj = {
        apiSuccess: false,
        data: [],
      };
      setDatabaseManagementFields(responseObj);
    }
    // getDatabaseManagementFieldsApiCall();
  }, [selectedNode, dateTimeRange]);

  useEffect(() => {
    if (successUpdated) {
      let responseObj = {
        apiSuccess: false,
        data: [],
      };
      //! sets senstive data fields
      dispatch({
        type: CLEAR_SENSITIVE_DATA_FIELDS
      });
      dispatch({ type: FETCH_SENSITIVE_DATA_FIELDS_REQUEST });
      //! sets All data fields
      dispatch({
        type: CLEAR_ALL_FIELDS_DATA
      });
      dispatch({ type: FETCH_ALL_FIELDS_DATA_REQUEST });
      //!Sets All Tables data
      setAllTablesData(responseObj);
      getAllTablesDataApiCall();
      //!Sets All DB management fields
      setDatabaseManagementFields(responseObj);
      getDatabaseManagementFieldsApiCall();
      setSpanDBStatements(responseObj);
      getSpanDbStatements(
        selectedNode,
        cubejsApi,
        redirectToLogin,
        setSpanDBStatements,
        getNodesResponse
      );
    }
  }, [successUpdated]);

  useEffect(() => {
    let temp = [];
    let selectedItems = [];
    if (allFieldsData.data.length > 0) {
      let obj = {};
      allFieldsData.data.forEach((field) => {
        obj = {
          severity: `${field[`Contents.category`]}`,
          value: `${field[`Contents.tableName`]}.${field[`Contents.columnName`]
            }`,
          id: `${field[`Contents.sensitivityId`]}`,
          tableName: `${field[`Contents.tableName`]}`,
          fieldName: `${field[`Contents.columnName`]}`,
          fileOwner: `${field[`Contents.fileOwner`]}`,
          lastAccess: `${field[`Contents.lastAccess`]}`,
          lastDLPScanTime: `${field[`Contents.lastDLPScanTime`]}`,
          dataSensitivityType: `${field[`Contents.dataSensitivityType`]}`,
        };
        temp.push(obj);
      });
      sensitiveDataFields.data.forEach((data) => {
        temp.forEach((each) => {
          if (each.value === data[`Contents.columnName`]) {
            selectedItems.push(each.id);
          }
        });
      });
    }

    setInitialSensitiveData(selectedItems);
    setAllFieldsConsolidated(temp);
  }, [sensitiveDataFields, allFieldsData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditClick = (event) => {
    if (value > 1) {
      setValue(1);
    }
  };

  const getValue = (selectedFieldForSeverity) => {
    let temp = "";
    selected.forEach((each) => {
      if (each.id === selectedFieldForSeverity) {
        temp = each.severity;
      }
    });
    return temp;
  };

  const handleSeverity = (field, severity) => {
    allFieldsConsolidated.forEach((each) => {
      if (each.id === field) {
        each.severity = severity;
        let temp = { id: each.id, severity: severity };
        setSelected([...selected, temp]);
      }
    });
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
    arrow: {
      fontSize: 16,
      width: 17,
      "&::before": {
        border: "1px solid #fff",
        backgroundColor: "white",
        boxSizing: "border-box",
        boxShadow: " 0 0 2px #0004",
      },
    },
  }))(Tooltip);

  const personalAvatarSources = {
    DROPBOX: { alt: 'Dropbox', src: dropboxfolder, title: "Dropbox: " },
    GOOGLEDRIVE: { alt: 'Google Drive', src: googleFolderIcon, title: "Google Drive: " },
    ONEDRIVE: { alt: 'OneDrive', src: onedrivefolder, title: "OneDrive: " }
  };

  // Returns an Avatar component based on the selectedNodeSubType.
  const personalAvatars = () => {
    const avatar =
      personalAvatarSources[selectedNodeSubType] ||
      personalAvatarSources[ONEDRIVE];

    return <Avatar alt={avatar.alt} src={avatar.src} />;
  };

  const siteAvatarSources = {
    DROPBOX: { alt: 'DropBox Sites', src: dropBoxSite },
    GOOGLEDRIVE: { alt: 'Google Drive Sites', src: "/dataSourceIcons/GoogleDriveIcon.svg" },
    ONEDRIVE: { alt: 'OneDrive Sites', src: onedriveweb }
  };

  // Returns an Avatar component based on the selectedNodeSubType.
  const siteAvatars = () => {
     const avatar =
     siteAvatarSources[selectedNodeSubType] ||
     siteAvatarSources[ONEDRIVE];

    return <Avatar alt={avatar.alt} src={avatar.src} />;
  };

  // Returns a personalized title based on the selectedNodeSubType.
  const personalTitle = () => {
    const avatar =
      personalAvatarSources[selectedNodeSubType] ||
      personalAvatarSources[ONEDRIVE];

    return avatar.title;
  };

  const renderContainer = (field) => {
    if (field[`Contents.tableType`] === 'Personal') {
      return <Grid item xs={12} md={3} sm={6}>
        <Chip
          title={personalTitle() + field[`Contents.tableName`]}
          label={field[`Contents.tableName`]}
          avatar={personalAvatars()}
          variant="outlined"
          className="drive-name"
        />
      </Grid>
    } else if (field[`Contents.tableType`] === 'Shared') {
      return <Grid item xs={12} md={3} sm={6}>
        <Chip
          title={personalTitle() + field[`Contents.tableName`]}
          label={field[`Contents.tableName`]}
          avatar={siteAvatars()}
          variant="outlined"
          className="drive-name"
        />
      </Grid>
    } else {
      return <Grid item xs={12} md={3} sm={6}>
        <Chip
          title={field[`Contents.tableName`]}
          label={field[`Contents.tableName`]}
          className="drive-name"
        />
      </Grid>
    }
  }
  const handleOneDriveToolTip = (field) => {
    return (
      <React.Fragment>
        <Typography color="inherit">
          <u>File Information</u>
        </Typography>
        <span>
          Drive Name: <b>{`${field["Contents.tableName"] || field.tableName}`}</b>
        </span>
        <br />
        <span>
          File Name: <b>{`${field[`Contents.columnName`] || field.fieldName}`}</b>
        </span>
      </React.Fragment>
    );
  };
  const handleToolTip = (field) => {
    return (
      <React.Fragment>
        <Typography color="inherit">
          <u>Field Information</u>
        </Typography>
        <span>
          Table Name: <b>{field["Contents.tableName"]}</b>
        </span>
        <br />
        <span>
          Field Name: <b>{field[`Contents.columnName`]}</b>
        </span>
      </React.Fragment>
    );
  };

  const allFieldsComponent = () => {
    if (viewTab === "tableView") {
      return (
        <>
          <InspectContentTable
            allFilesTableData={allFieldsData}
            loader={loader}
            totalCount={totalFilesCount}
            sourceName={sourceName} />
        </>
      );
    } else if (viewTab === "heatMapView") {
      return (
        <>
          <HeatMap />
        </>
      )
    } else if (viewTab === "publicFiles") {
      return (
        <>
          <InspectContentTable
            allFilesTableData={publicFieldsData}
            loader={loader}
            totalCount={totalPublicFileCount}
            sourceName={sourceName} />
        </>
      )
    } else { 
      // if public file is inactive load table view again
      return (
        <>
          <InspectContentTable
            allFilesTableData={allFieldsData}
            loader={loader}
            totalCount={totalFilesCount}
            sourceName={sourceName} />
        </>
      );
    }
  }

  const sensitiveFieldComponent = () => {
    if (viewTab === "publicFiles") {
      return (
        <>
          <InspectContentTable
            allFilesTableData={publicSensitiveFieldsData}
            loader={loader}
            totalCount={totalSensitivePublicFileCount}
            sourceName={sourceName} />
        </>
      )
    } else {
      return (
        <>
          <InspectContentTable
            allFilesTableData={sensitiveDataFields}
            loader={loader}
            totalCount={sensitiveTotalCount}
            sourceName={sourceName} />
        </>
      )
    }
  }

  const renderSourceLabel = () => {
    if(selectedNodeSubType === NTFS) {
      return "Folders & Permissions";
    } else {
      return "All Drives";
    }
  }

  const oneDriveTabs = () => {
    return (
      <div>
        <AppBar position="static" color="default" >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Sensitive Files" {...a11yProps(0)} />
            <Tab label="All Files" {...a11yProps(1)} />
            <Tab label={renderSourceLabel()} {...a11yProps(2)} />
            <Tab label="Access Statements" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
      </div>
    );
  }

  const nonOneDriveTabs = () => {
    return (
      <div>
        <AppBar position="static" color="default" >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Sensitive Data Fields" {...a11yProps(0)} />
            <Tab label="All Fields" {...a11yProps(1)} />
            <Tab label="All Tables" {...a11yProps(2)} />
            <Tab label="Database Statements" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
      </div>
    );
  }

  const tabsLabelSwitch = () => {
    if (cloudFileSystem) {
      return oneDriveTabs();
    } else {
      return nonOneDriveTabs();
    }
  }
  const disableFilter = () => {
    if(value === 0) {
      if (isEmpty(sensitiveDataFields.data) && isEmpty(filters)) {
        return true;
      } else {
        return false;
      }
    } else if (value === 1) {
      if (isEmpty(allFieldsData.data) && isEmpty(filters) || viewTab === "heatMapView") {
        return true;
      } else {
        return false;
      }
    }
  }
  const setSortAndFilter = () => {
    if (value === 1 && cloudFileSystem) {
      return "inspect-tabs all-files-inspect"
    } else if (value === 1) {
      return "inspect-tabs all-fields-inspect"
    } else {
      return "inspect-tabs"
    }
  }

  const viewSwitch = () => {
    return (
      <InspectViewSwitch
        showFilter={value}
        switchView={setViewTab}
        selectedNode={selectedNode}
        cloudFileSystem={cloudFileSystem}
      />
    )
  }

  const showFilter = () => {
    if (value === 0 || value === 1) {
      return (
        <InspectFilter
          showFilter={disableFilter()}
          cloudFileSystem={cloudFileSystem}
          value={value}
        />
      );
    } else {
      return null;
    }
  }
  const renderAllDrives = () => {
    if (selectedNodeSubType === NTFS) {
      return <UserFolders selectedNode={selectedNode}/>;
    }
  
    if (!allTablesData.apiSuccess) {
      return <LoaderComponent />;
    }
  
    return allTablesData.data.length > 0 ? (
      allTablesData.data.map((field) => renderContainer(field))
    ) : (
      <p>No table data for this node.</p>
    );
  };
  
  


  return (
    <div className={classes.root + " datamap-content"} >
      <div className="inspect-filter">
        {tabsLabelSwitch()}
        <div className={setSortAndFilter()}>
          {showFilter()}
          {viewSwitch()}
        </div>
      </div>
      <div className="dataMapContentGrid">
        <TabPanel value={value} index={0}>
          {sensitiveFieldComponent()}
        </TabPanel>
        <TabPanel value={value} index={1} className={cloudFileSystem ? "oneDrive-inspect-box" : ""}>
          {allFieldsComponent()}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container spacing={3}>
            {renderAllDrives()}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              {databaseManagementFields.apiSuccess === false && (
                <img
                  src={page_loader}
                  alt="loading"
                  style={{
                    position: "absolute",
                    marginLeft: "40%",
                    top: "40%",
                  }}
                ></img>
              )}
              {cloudFileSystem  ? (
                <EnhancedTable
                  tableData={
                    databaseManagementFields.data ? databaseManagementFields.data : []
                  }
                  headCells={headCellsForSqlStatements}
                  loc={"OneDrive"}
                />
              ) : (<RenderTabsInfo
                views={views}
                setViews={setViews}
                databaseManagementFields={databaseManagementFields}
                spanDBStatements={spanDBStatements}
              ></RenderTabsInfo>
              )}
            </Grid>
          </Grid>
        </TabPanel>
      </div>
      {loaderStatus ? <LoaderComponent /> : null}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        onExited={handleExited}
      >
        <Alert
          style={{
            width: "421px",
            height: "33px",
            background: "#2CE68F",
          }}
          onClose={handleClose}
          severity={successUpdated ? "success" : "error"}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
