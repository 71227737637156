import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import tableViewIcon from "../../assets/icons/tableViewIcon.svg";
import heatMapViewIcon from "../../assets/icons/heatMapViewIcon.svg";
import heatMapViewSelectedIcon from "../../assets/icons/heatMapViewSelectedIcon.svg";
import tableViewSelectedIcon from "../../assets/icons/tableViewSelectedIcon.svg";
import fullScreenViewIcon from "../../assets/icons/fullScreenViewIcon.svg";
import { ALL_FILES_LIST, SET_TAB_VIEW } from "../../redux/constants/constants";
import { useDispatch } from 'react-redux';
const publicFileActive = "/dashboardIcons/publicActive.svg"
const publicFileInActive = "/dashboardIcons/publicInactive.svg"
const nonPublicFileActive = "/dashboardIcons/nonPublicActive.svg"
const nonPublicFileInActive = "/dashboardIcons/nonPublicInactive.svg"
const useStyles = makeStyles({
  inspectButtons: {
    padding: '0px',
    minWidth: '25px',
    height: '25px',
  },
});

function InspectViewSwitch({ showFilter, switchView, cloudFileSystem, selectedNode }) {
  const currentLocation = window.location.pathname;
  //Tab 0 is for Sensitive files/fields and 1 is for All files/fields
  const viewTabs = [0, 1];
  const [heatMapIcon, setHeatMapIcon] = useState(heatMapViewIcon);
  const [tableIcon, setTableIcon] = useState(tableViewSelectedIcon);
  const [publicFileIcon, setPublicFileIcon] = useState(publicFileActive);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleViewSwitch = (view) => {
    if (view === "heatMapView") {
      setHeatMapIcon(heatMapViewSelectedIcon);
      setTableIcon(tableViewIcon);
      setPublicFileIcon(publicFileInActive);
    } else if (view === "tableView") {
      setTableIcon(tableViewSelectedIcon);
      setHeatMapIcon(heatMapViewIcon);
      setPublicFileIcon(publicFileInActive);
    } else if (view === "publicFiles") {
      setPublicFileIcon(publicFileActive);
      setHeatMapIcon(heatMapViewIcon);
      setTableIcon(tableViewIcon);
    } else if (view === "nonPublicFiles") {
      setPublicFileIcon(publicFileInActive);
      setHeatMapIcon(heatMapViewIcon);
      setTableIcon(tableViewIcon);
    }
    switchView(view);
  };

  useEffect(() => {
    handleViewSwitch("tableView");
  }, [selectedNode, showFilter]);


  const displaySwitchViews = () => {
    if (showFilter === 1 && cloudFileSystem) {
      return (
        <>
          <div>
            <Button classes={{ root: classes.inspectButtons }} onClick={() => handleViewSwitch("heatMapView")}>
              <img className='public-file-icon' src={heatMapIcon} alt='heatMapViewIcon' title="Heat Map View" />
            </Button>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
  const displayFullScreenView = () => {
    //This full screen view option should be displayed for both 0 and 1 tabs
    if (currentLocation !== ALL_FILES_LIST && viewTabs.includes(showFilter)) {
      dispatch({type: SET_TAB_VIEW, payload: showFilter});
      return (
        <>
          <div className="inspect-view-btn-container">
            <div>
            <Button classes={{ root: classes.inspectButtons }} onClick={(e) =>
              history.push(`/tenant/fileList`)
            }>
              <img className='public-file-icon' src={fullScreenViewIcon} alt='fullScreenViewIcon' title='Full Screen View' />
            </Button>
            </div>
            {cloudFileSystem && 
              <>
                <div>
                  <Button classes={{ root: classes.inspectButtons }} onClick={() => handleViewSwitch("nonPublicFiles")}>
                    <img className='public-file-icon' src={publicFileIcon === publicFileInActive?nonPublicFileActive:nonPublicFileInActive} alt='publicFileIcon' title="All Assets" />
                  </Button>
                </div>
                <div>
                  <Button classes={{ root: classes.inspectButtons }} onClick={() => handleViewSwitch("publicFiles")}>
                    <img className='public-file-icon' src={publicFileIcon} alt='publicFileIcon' title="Public Assets" />
                  </Button>
                </div>
              </>
            }
          </div>
        </>
      );
    } else if (currentLocation === ALL_FILES_LIST) {
      return (
        <>
          <div className="inspect-view-btn-container">
            {cloudFileSystem && 
              <>
                <div>
                  <Button classes={{ root: classes.inspectButtons }} onClick={() => handleViewSwitch("nonPublicFiles")}>
                    <img className='public-file-icon' src={publicFileIcon === publicFileInActive?nonPublicFileActive:nonPublicFileInActive} alt='publicFileIcon' title="All Assets" />
                  </Button>
                </div>
                <div>
                  <Button classes={{ root: classes.inspectButtons }} onClick={() => handleViewSwitch("publicFiles")}>
                    <img className='public-file-icon' src={publicFileIcon} alt='publicFileIcon' title="Public Assets" />
                  </Button>
                </div>
              </>
            }
          </div>
        </>
      );
    } else {
      return null;
    }
  }
  const switchViewClass = () => {
    if(showFilter === 0) {
      return 'inspect-change-view';
    } else if (showFilter === 1) {
      return 'inspect-change-view switchView'
    }
  }
  return (
    <>
      <div className={switchViewClass()}>
        {displaySwitchViews()}
        {displayFullScreenView()}
      </div>
    </>
  )
}

export default InspectViewSwitch