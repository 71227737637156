import { isNull, isUndefined, method } from "lodash";
import {
  CLEAR_SYS_GUARD_TABLE_DATA,
  FETCH_SYS_GUARD_DATA_SOURCE,
  fetchApplicationAnomaliesCount,
  fetchApplicationBasedUserCounts,
  fetchCityCoordinates,
  fetchLocationAnomaliesCount,
  fetchLocationBasedApplications,
  fetchSidePopupData,
  fetchSysGuardApplicationAPI,
  fetchSysGuardApplicationBasedAnomalies,
  fetchSysGuardApplicationBasedUsers,
  fetchSysGuardLocationBasedAnomalies,
  fetchSysGuardLocationBasedApplications,
  fetchSysGuardLocationBasedUsers,
  fetchSysGuardLocationData,
  fetchSysGuardUserAnomaliesApi,
  fetchSysGuardUserApplicationApi,
  fetchSysGuardUserDeviceApi,
  fetchSysGuardUserDistinctLocations,
  fetchSysGuardUserLocationApi,
  fetchSysGuardUsersAPI,
  fetchUserAnomaliesSidePopupData,
  fetchUserBasedDevicesCount,
  GET_SYS_GUARD_APPLICATION,
  GET_SYS_GUARD_CITY_DATA,
  GET_SYS_GUARD_DISTINCT_LOCATIONS,
  GET_SYS_GUARD_HEADER_DATA,
  GET_SYS_GUARD_LOCATION,
  GET_SYS_GUARD_POPUP_DATA,
  GET_SYS_GUARD_SIDE_POPUP_DATA,
  GET_SYS_GUARD_USERS,
  getDatasourceWithType,
  SET_SYS_GUARD_CITY_DATA,
  SET_SYS_GUARD_DATASOURCE,
  SET_SYS_GUARD_DISTINCT_LOCATIONS,
  SET_SYS_GUARD_HEADER_DATA,
  SET_SYS_GUARD_PAGE_LOADER,
  SET_SYS_GUARD_POPUP_DATA,
  SET_SYS_GUARD_POPUP_TOTAL_ELEMENTS,
  SET_SYS_GUARD_SIDE_POPUP_DATA,
  SET_SYS_GUARD_TABLE_DATA,
  SET_TOTAL_ELEMENTS,
} from "../sysGuardConstants";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import {
  getCubejsApi,
  redirectToLogin,
} from "../../../components/common/Dashboard/cubejs-util";
import { generateHeatmapData } from '../../../components/common/Dashboard/utils';
import { maskUsername } from "../SysGuardCommonComp";

const heatmap = { rows: 8, columns: 6 };
const { takeEvery, put, select, call } = require("redux-saga/effects");
function* fetchSysGuardHeaderData() {
  const cubejs = getCubejsApi();
  yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true });
  const locationHeaderDataQuery = {
    measures: [
      "SysguardLocationCount.totalUniqueLocationCount",
      "SysguardLocationCount.alertUniqueLocationCount",
    ],
  };
  const locationChartQuery = {
    dimensions: [
      "SysguardLocationCount.latitude",
      "SysguardLocationCount.longitude",
      "SysguardLocationCount.category",
    ],
    order: {
      "SysguardLocationCount.totalUniqueLocationCount": "desc",
    },
  };
  const userChartQuery = {
    dimensions: ["SysguardUserChart.name", "SysguardUserChart.alertCount"],
    order: {
      "SysguardUserChart.username": "asc",
    },
  };
  const applicationQuery = {
    measures: [
      "SysguardApplicationCount.totalApplication",
      "SysguardApplicationCount.suspiciousApplication",
    ],
  };
  const applicationChartQuery = {
    dimensions: [
      "SysguardApplicationCount.appDetails",
      "SysguardApplicationCount.alertCount",
    ],
    order: {
      "SysguardApplicationCount.alertCount": "desc",
    },
  };
  const deviceHeaderData = {
    measures: [
      "SysguardDeviceCount.totalDevices",
      "SysguardDeviceCount.suspiciousDevice",
    ],
  };
  const MFAHeaderData = {
    dimensions: ["SysguardMFACount.enabled", "SysguardMFACount.total"],
    order: {
      "SysguardMFACount.enabled": "asc",
    },
  };
  const suspiciousHeaderData = {
    measures: [
      "SysguardSuspiciousUsers.totalUsers",
      "SysguardSuspiciousUsers.suspiciousUser",
    ],
  };

  try {
    const locationData = yield cubejs.load(locationHeaderDataQuery);
    const applicationData = yield cubejs.load(applicationQuery);
    const locationChartData = yield cubejs.load(locationChartQuery);
    const userChartData = yield cubejs.load(userChartQuery);
    const applicationChartData = yield cubejs.load(applicationChartQuery);
    const deviceData = yield cubejs.load(deviceHeaderData);
    const MFAData = yield cubejs.load(MFAHeaderData);
    const suspiciousData = yield cubejs.load(suspiciousHeaderData);

    const location = locationData.rawData();
    const application = applicationData.rawData();
    const locationChart = locationChartData.rawData();
    const userChart = userChartData.rawData();
    const applicationChart = applicationChartData.rawData();
    const device = deviceData.rawData();
    const MFA = MFAData.rawData();
    const suspicious = suspiciousData.rawData();
    const { heatmapData, heatmapDetails } = generateHeatmapData(userChart, heatmap, "SysguardUserChart.alertCount", "SysguardUserChart.name");
    const systemGuardData = {
      location: {
        totalCount: parseInt(
          location[0]["SysguardLocationCount.totalUniqueLocationCount"]
        ),
        alertCount: parseInt(
          location[0]["SysguardLocationCount.alertUniqueLocationCount"]
        ),
      },
      application: {
        totalCount: parseInt(
          application[0]["SysguardApplicationCount.totalApplication"]
        ),
        alertCount: parseInt(
          application[0]["SysguardApplicationCount.suspiciousApplication"]
        ),
      },
      locationChart: locationChart.map((e) => {
        return {
          position: [
            parseFloat(e["SysguardLocationCount.latitude"]),
            parseFloat(e["SysguardLocationCount.longitude"]),
          ],
          label: "",
          category: e["SysguardLocationCount.category"],
        };
      }),
      userChart: { heatmapData, heatmapDetails },
      applicationChart: applicationChart.map((e) => {
        return {
          appDetails: e["SysguardApplicationCount.appDetails"],
          alertCount: e["SysguardApplicationCount.alertCount"],
        };
      }),
      device: {
        totalCount: parseInt(device[0]["SysguardDeviceCount.totalDevices"]),
        alertCount: parseInt(device[0]["SysguardDeviceCount.suspiciousDevice"]),
      },
      mfa: {
        enabledCount: parseInt(MFA[0]["SysguardMFACount.enabled"]),
        totalCount: parseInt(MFA[0]["SysguardMFACount.total"]),
      },
      suspicious: {
        totalCount: parseInt(
          suspicious[0]["SysguardSuspiciousUsers.totalUsers"]
        ),
        alertCount: parseInt(
          suspicious[0]["SysguardSuspiciousUsers.suspiciousUser"]
        ),
      },
    };

    yield put({ type: SET_SYS_GUARD_HEADER_DATA, payload: systemGuardData });
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  }
}

function* fetchSysGuardDatasource(action) {
  yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true });
  const type = action.payload;
  let options = {
    method: "GET",
    url: getDatasourceWithType(type),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res?.data;
      yield put({ type: SET_SYS_GUARD_DATASOURCE, payload: data });
      yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
    }
  } catch (err) {
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
    console.log(err);
  }
}

function* getSysGuardUsers(action) {
  yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true });
  const { id, currentPage, pageSize, isIncognito } = action.payload;
  const sysData = yield select((state) => state.SysGuardReducer);
  const { fromTimeStamp, toTimeStamp } = sysData;

  const options = {
    method: "GET",
    url: fetchSysGuardUsersAPI(
      fromTimeStamp,
      toTimeStamp,
      id,
      currentPage,
      pageSize
    ),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      let data = res?.data?.content || [];
        // Mask username if incognito mode is enabled
        if (isIncognito) {
          data = data.map((user) => ({
            ...user,
            userName: maskUsername(user.userName),
          }));
        }
      const totalElements = res?.data?.totalElements;
      yield put({ type: SET_TOTAL_ELEMENTS, payload: totalElements });
      yield put({ type: SET_SYS_GUARD_TABLE_DATA, payload: data });
      yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
    }
  } catch (err) {
    console.log(err, "error");
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  }
}

function* getSysGuardLocation(action) {
  yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true });
  const { id, currentPage, pageSize } = action.payload;
  const sysData = yield select((state) => state.SysGuardReducer);
  const { fromTimeStamp, toTimeStamp } = sysData;

  const options = {
    method: "GET",
    url: fetchSysGuardLocationData(
      fromTimeStamp,
      toTimeStamp,
      id,
      currentPage,
      pageSize
    ),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res?.data?.content;
      const totalElements = res?.data?.totalElements;
      yield put({ type: SET_TOTAL_ELEMENTS, payload: totalElements });
      yield put({ type: SET_SYS_GUARD_TABLE_DATA, payload: data });
      yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
    }
  } catch (err) {
    console.log(err, "error");
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  }
}

function* getSysGuardApplication(action) {
  yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true });
  const { id, currentPage, pageSize } = action.payload;
  const sysData = yield select((state) => state.SysGuardReducer);
  const { fromTimeStamp, toTimeStamp } = sysData;

  const options = {
    method: "GET",
    url: fetchSysGuardApplicationAPI(
      fromTimeStamp,
      toTimeStamp,
      id,
      pageSize,
      currentPage
    ),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res?.data?.content;
      const totalElements = res?.data?.totalElements;
      yield put({ type: SET_TOTAL_ELEMENTS, payload: totalElements });
      yield put({ type: SET_SYS_GUARD_TABLE_DATA, payload: data });
      yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
    }
  } catch (err) {
    console.log(err, "error");
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  }
}

function* getSysGuardPopupData(action) {
  yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true });

  const { dataSourceId, actorId, currentPage, pageSize, loc, country, application } =
    action.payload;
  const { fromTimeStamp, toTimeStamp } = yield select(
    (state) => state.SysGuardReducer
  );

  // Map location to the corresponding API function
  const apiMap = {
    userLocations: fetchSysGuardUserLocationApi,
    userDevices: fetchSysGuardUserDeviceApi,
    userAnomalies: fetchSysGuardUserAnomaliesApi,
    locationBasedUsers: fetchSysGuardLocationBasedUsers,
    locationBasedAnomalies: fetchSysGuardLocationBasedAnomalies,
    locationBasedApplications: fetchSysGuardLocationBasedApplications,
    applicationBasedUsers:  fetchSysGuardApplicationBasedUsers,
    applicationBasedAnomalies: fetchSysGuardApplicationBasedAnomalies,
  };

  // Select the correct API function or default to `fetchSysGuardUserApplicationApi`
  const fetchAPI = apiMap[loc] || fetchSysGuardUserApplicationApi;

  const options = {
    method: "GET",
    url: fetchAPI(
      fromTimeStamp,
      toTimeStamp,
      dataSourceId,
      actorId,
      pageSize,
      currentPage,
      country,
      application
    ),
    headers: getAxiosHeaders(true),
  };

  try {
    const res = yield getService(options);
    if (res?.status === 200) {
      yield put({
        type: SET_SYS_GUARD_POPUP_TOTAL_ELEMENTS,
        payload: res.data?.totalElements,
      });
      yield put({ type: SET_SYS_GUARD_POPUP_DATA, payload: res.data?.content });
    }
  } catch (err) {
    console.error("Error fetching SysGuard Popup Data:", err);
  } finally {
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  }
}

function* getSysGuardSidePopupData(action) {
  yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true });
  const { dataSourceId, actorId, loc, country, application } = action.payload;
  const { fromTimeStamp, toTimeStamp } = yield select(
    (state) => state.SysGuardReducer
  );
  const getLocation = () => {
    if (loc === "userLocations") {
      return "city";
    } else if (loc === "userDevices") {
      return "device";
    } else if (loc === "userApplications") {
      return "application";
    }
  };
  const getAPIBasedOnLocation = () => {
    const apiMap = {
      locationBasedAnomalies: () =>
        fetchLocationAnomaliesCount(
          fromTimeStamp,
          toTimeStamp,
          dataSourceId,
          country,
          getLocation()
        ),

        userDevices: () => 
          fetchUserBasedDevicesCount(
            fromTimeStamp,
            toTimeStamp,
            dataSourceId,
            actorId
          ),

      locationBasedApplications: () =>
        fetchLocationBasedApplications(
          fromTimeStamp,
          toTimeStamp,
          dataSourceId,
          country
        ),

      userAnomalies: () =>
        fetchUserAnomaliesSidePopupData(
          fromTimeStamp,
          toTimeStamp,
          dataSourceId,
          actorId
        ),

      applicationBasedAnomalies: () =>
        fetchApplicationAnomaliesCount(
          fromTimeStamp,
          toTimeStamp,
          dataSourceId,
          application
        ),
      applicationBasedUsers: () => 
        fetchApplicationBasedUserCounts(
          fromTimeStamp,
          toTimeStamp,
          dataSourceId,
          application
        )
    };

    return apiMap[loc]
      ? apiMap[loc]()
      : fetchSidePopupData(
          fromTimeStamp,
          toTimeStamp,
          dataSourceId,
          actorId,
          getLocation()
        );
  };
  
  const options = {
    method: "GET",
    url: getAPIBasedOnLocation(),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res?.status === 200) {
      yield put({ type: SET_SYS_GUARD_SIDE_POPUP_DATA, payload: res.data });
    }
  } catch (err) {
    console.log(err, "error");
  } finally {
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  }
}

function* getSysGuardDistinctLocations(action) {
  yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true });
  const { dataSourceId, actorId } = action.payload;
  const { fromTimeStamp, toTimeStamp } = yield select(
    (state) => state.SysGuardReducer
  );

  const options = {
    method: "GET",
    url: fetchSysGuardUserDistinctLocations(
      fromTimeStamp,
      toTimeStamp,
      dataSourceId,
      actorId
    ),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res?.status === 200) {
      const data = res?.data.map((e) => {
        return {
          position: [parseFloat(e.latitude), parseFloat(e.longitude)],
          label: "",
          category: "",
        };
      });
      yield put({ type: SET_SYS_GUARD_DISTINCT_LOCATIONS, payload: data });
    }
  } catch (err) {
    console.log(err, "Error");
  } finally {
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  }
}

// Function to fetch city coordinates from OpenStreetMap
const getCityCoordinates = async (city, countryCode) => {
  const url = fetchCityCoordinates(city, countryCode);
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.length > 0) {
      return { lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) };
    }
  } catch (error) {
    console.error("Geocoding error:", error);
  }
  return null;
};

// Function to process mapData & count unique users per city
const processMapData = async (mapData, countryCode) => {
  const cityUserMap = new Map();
  const unknownCities = [];

  // Iterate over mapData to group users by city & handle unknown cities
  mapData.forEach(({ city, userName, latitude, longitude }) => {
    if (!city || city === "Not Available") {
      // If city is unknown, store it separately with provided lat/lng
      if (
        !isNull(latitude) &&
        !isUndefined(latitude) &&
        !isNull(longitude) &&
        !isUndefined(longitude)
      ) {
        unknownCities.push({
          name: "Unknown",
          users: 1,
          lat: latitude,
          lng: longitude,
        });
      }
    } else {
      // If city exists, store unique users in a Set
      if (!cityUserMap.has(city)) {
        cityUserMap.set(city, new Set());
      }
      cityUserMap.get(city).add(userName);
    }
  });

  // Fetch coordinates for known cities and map user count
  const cityPromises = [...cityUserMap.entries()].map(async ([city, users]) => {
    const coordinates = await getCityCoordinates(city, countryCode);
    return coordinates
      ? {
          name: city,
          users: users.size, // Unique user count per city
          lat: coordinates.lat,
          lng: coordinates.lng,
        }
      : null;
  });

  // Resolve all city coordinate fetches and filter out invalid ones
  const processedCities = (await Promise.all(cityPromises)).filter(Boolean);

  // Combine known and unknown city data
  return [...processedCities, ...unknownCities];
};




function* fetchCityDataSaga(action) {
  try {
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: true });
    const processedCities = yield call(processMapData, action.payload.mapData, action.payload.countryCode);
    // Find the city with the highest number of users
    // We use .reduce() to iterate over the array and keep track of the city with the most users.
    // If the array is empty, we return null.
    const highestCity = processedCities.length
      ? processedCities.reduce((prev, curr) =>
          curr.users > prev.users ? curr : prev
        )
      : null;
    yield put({ type: SET_SYS_GUARD_CITY_DATA, payload:{ cities: processedCities, mapCenter: highestCity ? [highestCity.lat, highestCity.lng] : null }});
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  } catch (error) {
    console.error("Error fetching city data:", error);
    yield put({ type: SET_SYS_GUARD_PAGE_LOADER, payload: false });
  }
}

function* SysGuardSaga() {
  yield takeEvery(FETCH_SYS_GUARD_DATA_SOURCE, fetchSysGuardDatasource);
  yield takeEvery(GET_SYS_GUARD_USERS, getSysGuardUsers);
  yield takeEvery(GET_SYS_GUARD_LOCATION, getSysGuardLocation);
  yield takeEvery(GET_SYS_GUARD_APPLICATION, getSysGuardApplication);
  yield takeEvery(GET_SYS_GUARD_HEADER_DATA, fetchSysGuardHeaderData);
  yield takeEvery(GET_SYS_GUARD_POPUP_DATA, getSysGuardPopupData);
  yield takeEvery(GET_SYS_GUARD_SIDE_POPUP_DATA, getSysGuardSidePopupData);
  yield takeEvery(
    GET_SYS_GUARD_DISTINCT_LOCATIONS,
    getSysGuardDistinctLocations
  );
  yield takeEvery(GET_SYS_GUARD_CITY_DATA, fetchCityDataSaga);
}

export default SysGuardSaga;
