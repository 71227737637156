import React from "react";
import "../views/userAccessRights/userAccessRights.scss";
import CanAllow from "./CanAllow";

const Toggler = ({ togglers, setTogglers, handleToggle, show, history, loc, permissionBased }) => {
  const togglerClickHandler = (name) => {
    const togglersArr = togglers;
    const currentTogglerArr = togglersArr.map((el) => {
        if (el.name === name) {
          el.state = "active";
          return el;
        } else {
          el.state = "inactive";
          return el;
        }
    });

    setTogglers([...currentTogglerArr]);
    if (handleToggle) {
      handleToggle(name);
    }
  };

/**
 * handleSummaryClasses function determines the CSS class for a given element based on its state and the current location (loc).
 * The returned class reflects the visual style for the active or inactive state of the element in different sections of the application.
 *
 * @param {Object} el - The element object with a 'state' property indicating its active or inactive state.
 * @returns {string} The CSS class name representing the visual style for the element based on its state and the current location.
 */
const handleSummaryClasses = (el) => {
  // Check if the element is in an 'active' state
  if (el.state === "active") {
    // Determine the class based on the current location (loc) for active state
    if (loc === "inspectContent") {
      return "inspect_toggler_active";
    } else if (loc === "summary") {
      return "systemSummary-text active";
    } else {
      return "header_toggler_active";
    }
  } else {
    // Determine the class based on the current location (loc) for inactive state
    if (loc === "inspectContent") {
      return "inspect_toggler_inactive";
    } else if (loc === "summary") {
      return "systemSummary-text inactive";
    } else {
      return "header_toggler_inactive";
    }
  }
};

  //The toggler which does not have count in it 
  const renderToggler = (el) => (
    <div
      className={handleSummaryClasses(el)}
      onClick={() => togglerClickHandler(el.name)}
    >
      <div>{el.name}</div>
    </div>
  );

  const renderPermissionBasedToggler = (el) => {
    // Render toggler based on permissions
    if (permissionBased) {
      return <CanAllow needs={{ permission: [el.permission] }}>{renderToggler(el)}</CanAllow>
    } else {
      return renderToggler(el);
    }
  }
  //The toggler which have count in it 
  const renderTogglerCount = (el) => (
    <div
      className={
        el.state === "active"
          ? loc === "inspectContent"
            ? "inspect_toggler_active"
            : "header_toggler_active toggle_switch"
          : loc === "inspectContent"
          ? "inspect_toggler_inactive"
          : "header_toggler_inactive toggle_switch"
      }
      onClick={() => togglerClickHandler(el.name)}
    >
      <div className={el.state === "active" ? "toggle_switch_name active":"toggle_switch_name"}>{el.name}:</div>
      <div className={el.state === "active" ? "toggle_switch_count active":"toggle_switch_count"}>{el.count}</div>
    </div>
  );

  //render toggler if you have count
  const renderTogglerData = () => {
    if (togglers.length > 0) {
      return togglers.map((el) => {
        if (el.count>=0) {
          return renderTogglerCount(el)
        } else {
          return renderPermissionBasedToggler(el)
        }
      });
    }
  };

  const renderMainContainerClassName = () => {
    if (loc === "inspectContent") {
      return "inspect_toggler";
    } else if (loc === "dashboard") {
      return "dashboard_toggler";
    } else {
      return "header_toggler";
    }
  }

  return (
    <>
      <div className={renderMainContainerClassName()}>
        {renderTogglerData()}
      </div>
    </>
  );
};

export default Toggler;
