import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getCubejsApi, redirectToLogin } from '../../components/common/Dashboard/cubejs-util';
import { fetchDMContentTabChildNodes } from "../../redux/actions/DataMapContentTabAction";
import { isEmpty, method } from 'lodash';
import {
  SET_ALL_FIELDS_DATA,
  SET_PUBLIC_TOTAL_FILE_COUNT,
  SET_PUBLIC_FIELDS_DATA,
  SET_LOADER,
  SET_SENSITIVE_DATA_FIELDS,
  SET_TOTAL_FILE_COUNT,
  SET_TOTAL_SENSITIVE_COUNT,
  FETCH_ALL_FIELDS_DATA_REQUEST,
  FETCH_PUBLIC_FIELDS_DATA_REQUEST,
  FETCH_SENSITIVE_DATA_FIELDS_REQUEST,
  SET_NODES_RESPONSE,
  getAllCategories,
  FETCH_ALL_CATEGORIES,
  SET_ALL_CATEGORIES,
  SAVE_FILE_CATEGORIES,
  saveCategoriesUrl,
  GET_OVERALL_CATEGORY,
  getFileCategory,
  SET_FINAL_FILE_TYPE,
  FETCH_PUBLIC_SENSITIVE_FIELDS_DATA_REQUEST,
  SET_PUBLIC_SENSITIVE_FIELDS_DATA,
  SET_TOTAL_PUBLIC_SENSITIVE_COUNT,
  SET_FILE_LINK_COUNT,
} from '../constants/constants';
import { getAxiosHeaders, getService } from '../../library/RestAPI';

function* fetchData(apiQuery, fileCountQuery, resultType, resultCountType) {
  yield put({
    type: SET_LOADER,
    payload: true
  });
  let nodeData = yield select((state) => state.InspectContentReducer);
  let selectedNode = yield select((state) => state.selectedNode.selectedNodeId);
  let order = (nodeData.sortByValue || nodeData.orderByValue) ? [[nodeData.sortByValue, nodeData.orderByValue]] : []
  let filters = nodeData.filters
  const cubejsApi = getCubejsApi();
  let nodes = [];
  let response;
  let fileCount;
  const queryType = "multi";
  const nodeQuery = {
    dimensions: ["Nodes.nodeId"],
    timeDimensions: [],
    order: {},
    filters: [],
  };
  if (selectedNode) {
    nodeQuery.filters.push({
      member: "Nodes.parentId",
      operator: "equals",
      values: [selectedNode],
    });
  }
  const nodesResponse = yield fetchDMContentTabChildNodes(nodeQuery, queryType);
  yield put({
    type: SET_NODES_RESPONSE,
    payload: nodesResponse
  });
  if (nodesResponse) {
    nodes.push(selectedNode);
    if (!isEmpty(nodesResponse)) {
      nodesResponse.map((each) => {
        nodes.push(each[`Nodes.nodeId`]);
      });
    }
    fileCountQuery.filters.push({
      member: "Contents.nodeId",
      operator: "equals",
      values: nodes,
    });
    apiQuery.filters.push({
      member: "Contents.nodeId",
      operator: "equals",
      values: nodes,
    });
    if (!isEmpty(filters)) {
      filters.map((each) => {
        apiQuery.filters.push(each)
        fileCountQuery.filters.push(each)
      });
    };
    if (!isEmpty(order)) {
      apiQuery.order = order
    };
    try {
      const fileCountResponse = yield cubejsApi.load(fileCountQuery);
      const resultSet = yield cubejsApi.load(apiQuery);
      response = resultSet.rawData();
      fileCount = fileCountResponse.rawData();
    } catch (error) {
      redirectToLogin(error);
    }
    if (!isEmpty(fileCount)) {
      yield put({
        type: resultCountType,
        payload: fileCount[0]["Contents.sensitivityInfoCount"]
      });
      yield put({
        type: SET_LOADER,
        payload: false
      });
    }
    if (!isEmpty(response)) {
      yield put({
        type: resultType,
        payload: response
      });
      yield put({
        type: SET_LOADER,
        payload: false
      });
    } else {
      yield put({
        type: resultType,
        payload: []
      });
      yield put({
        type: SET_LOADER,
        payload: false
      });
    }
  } else {
    yield put({
      type: resultType,
      payload: []
    });
    yield put({
      type: SET_LOADER,
      payload: false
    });
  }
};

function* fetchSensitiveDataFields() {
  let nodeData = yield select((state) => state.InspectContentReducer);
  let limit = nodeData.pageSize;
  let offSet = nodeData.currentPage * nodeData.pageSize;
  const fileCountQuery = {
    measures: [
      "Contents.sensitivityInfoCount"
    ],
    filters: [
      {
        member: "Contents.category",
        operator: "contains",
        values: ["HIGH", "MEDIUM"],
      },
    ],
  };
  const queryString = {
    dimensions: [
      "Contents.columnName",
      "Contents.tableName",
      "Contents.sensitivityId",
      "Contents.finalType",
      "Contents.category",
      "Contents.fileOwner",
      "Contents.lastAccess",
      "Contents.firstAccess",
      "Contents.lastAccessTime",
      "Contents.dataSensitivityType",
      "Contents.lastDLPScanTime",
      "Contents.directoryName",
      "Contents.tableType",
      "Contents.tableId",
    ],
    limit: limit,
    offset: offSet,
    filters: [
      {
        member: "Contents.category",
        operator: "contains",
        values: ["HIGH", "MEDIUM"],
      },
    ],
    order: [["Contents.columnName", "asc"]]
  };
  yield call(fetchData, queryString, fileCountQuery, SET_SENSITIVE_DATA_FIELDS, SET_TOTAL_SENSITIVE_COUNT);
  yield put({ type: FETCH_PUBLIC_SENSITIVE_FIELDS_DATA_REQUEST });
}

function* fetchPublicSensitiveFieldsData() {
  let nodeData = yield select((state) => state.InspectContentReducer);
  let limit = nodeData.pageSize;
  let offSet = nodeData.currentPage * nodeData.pageSize;
  const fileCountQuery = {
    measures: [
      "Contents.sensitivityInfoCount"
    ],
    filters: [
      {
        member: "Contents.publicFile",
        operator: "equals",
        values: ["true"],
      },
      {
        member: "Contents.category",
        operator: "contains",
        values: ["HIGH", "MEDIUM"],
      },
    ],
  };
  const queryString = {
    dimensions: [
      "Contents.columnName",
      "Contents.tableName",
      "Contents.sensitivityId",
      "Contents.finalType",
      "Contents.category",
      "Contents.lastAccess",
      "Contents.firstAccess",
      "Contents.lastAccessTime",
      "Contents.fileOwner",
      "Contents.dataSensitivityType",
      "Contents.lastDLPScanTime",
      "Contents.directoryName",
      "Contents.tableType",
      "Contents.tableId",
      "Contents.publicFile"
    ],
    limit: limit,
    offset: offSet,
    filters: [
      {
        member: "Contents.publicFile",
        operator: "equals",
        values: ["true"],
      },
      {
        member: "Contents.category",
        operator: "contains",
        values: ["HIGH", "MEDIUM"],
      },
    ],
    order: [["Contents.columnName", "asc"]]
  };
  yield call(fetchData, queryString, fileCountQuery, SET_PUBLIC_SENSITIVE_FIELDS_DATA, SET_TOTAL_PUBLIC_SENSITIVE_COUNT);
}

function* fetchPublicFieldsData() {
  let nodeData = yield select((state) => state.InspectContentReducer);
  let limit = nodeData.pageSize;
  let offSet = nodeData.currentPage * nodeData.pageSize;
  const fileCountQuery = {
    measures: [
      "Contents.sensitivityInfoCount"
    ],
    filters: [
      {
        member: "Contents.publicFile",
        operator: "equals",
        values: ["true"],
      },
    ],
  };
  const queryString = {
    dimensions: [
      "Contents.columnName",
      "Contents.tableName",
      "Contents.sensitivityId",
      "Contents.finalType",
      "Contents.category",
      "Contents.lastAccess",
      "Contents.firstAccess",
      "Contents.lastAccessTime",
      "Contents.fileOwner",
      "Contents.dataSensitivityType",
      "Contents.lastDLPScanTime",
      "Contents.directoryName",
      "Contents.tableType",
      "Contents.tableId",
      "Contents.publicFile"
    ],
    limit: limit,
    offset: offSet,
    filters: [
      {
        member: "Contents.publicFile",
        operator: "equals",
        values: ["true"],
      },
    ],
    order: [["Contents.columnName", "asc"]]
  };
  yield call(fetchData, queryString, fileCountQuery, SET_PUBLIC_FIELDS_DATA, SET_PUBLIC_TOTAL_FILE_COUNT);
}

export const getFileLinksQuery = (id) => {
  return {
    dimensions: [
      "FileLinks.maskedLink",
    ],
    filters: [
      {
        member: "FileLinks.sensitivityId",
        operator: "equals",
        values: [`${id}`],
      },
      {
        member: "FileLinks.isPubliclyShared",
        operator: "equals",
        values: ["true"],
      },
    ],
  };
};

function* fetchAllFieldsData() {
  let nodeData = yield select((state) => state.InspectContentReducer);
  let limit = nodeData.pageSize;
  let offSet = nodeData.currentPage * nodeData.pageSize;
  const fileCountQuery = {
    measures: [
      "Contents.sensitivityInfoCount"
    ],
    filters: [],
  };
  const queryString = {
    dimensions: [
      "Contents.columnName",
      "Contents.tableName",
      "Contents.sensitivityId",
      "Contents.finalType",
      "Contents.category",
      "Contents.lastAccess",
      "Contents.firstAccess",
      "Contents.lastAccessTime",
      "Contents.fileOwner",
      "Contents.dataSensitivityType",
      "Contents.lastDLPScanTime",
      "Contents.directoryName",
      "Contents.tableType",
      "Contents.tableId",
    ],
    limit: limit,
    offset: offSet,
    filters: [],
    order: [["Contents.columnName", "asc"]]
  };
  yield call(fetchData, queryString, fileCountQuery, SET_ALL_FIELDS_DATA, SET_TOTAL_FILE_COUNT);
  yield put({ type: FETCH_PUBLIC_FIELDS_DATA_REQUEST });
};

function* fetchAllCategories () {
  const options = {
    method: "GET",
    url: getAllCategories(),
    headers: getAxiosHeaders(true)
  }
  try {
    const res = yield getService(options);
    if(res && res.status === 200){
      const categories = res?.data;
      yield put({type: SET_ALL_CATEGORIES, payload: categories})
    }
  } catch (error) {
    console.log(error);
  }
}

function* saveFileCategories(action) {
  const {id, category} = action.payload;
  const options = {
    method: "PUT",
    url: saveCategoriesUrl(id),
    headers: getAxiosHeaders(true),
    data: category
  }
  try {
    const res = yield getService(options);
    if(res && res.status === 204) {
      console.log("Successfully Saved the category");
      yield put({type: SET_FINAL_FILE_TYPE, payload: category.sensitiveFieldType})
      yield put({ type: FETCH_ALL_FIELDS_DATA_REQUEST });
      yield put({ type: FETCH_SENSITIVE_DATA_FIELDS_REQUEST });
    } else {
      console.log("Saving the category failed");
    }
  } catch (error) {
    console.log(error);
  }
}

function* getTheOverallCategory(action) {
  const id = action.payload;
  const cubejsApi = getCubejsApi();
  const dataQuery = getFileCategory(id);
  const fileLinksQuery = getFileLinksQuery(id);
  try {
    const res = yield cubejsApi.load(dataQuery);
    const responseFileLink = yield cubejsApi.load(fileLinksQuery);
    const resData = res.rawData() || "";
    const resFileLinkData = responseFileLink.rawData();
    yield put({type: SET_FINAL_FILE_TYPE, payload: resData[0]['Contents.finalType']})
    yield put({type: SET_FILE_LINK_COUNT, payload: {count:(resFileLinkData?.length || 0), links: resFileLinkData}})
  } catch (err) {
    console.log(err);
  }
}

function* InspectContentSaga() {
  yield takeEvery(FETCH_SENSITIVE_DATA_FIELDS_REQUEST, fetchSensitiveDataFields);
  yield takeEvery(FETCH_ALL_FIELDS_DATA_REQUEST, fetchAllFieldsData);
  yield takeEvery(FETCH_PUBLIC_FIELDS_DATA_REQUEST, fetchPublicFieldsData);
  yield takeEvery(FETCH_PUBLIC_SENSITIVE_FIELDS_DATA_REQUEST, fetchPublicSensitiveFieldsData);
  yield takeEvery(FETCH_ALL_CATEGORIES, fetchAllCategories);
  yield takeEvery(SAVE_FILE_CATEGORIES, saveFileCategories);
  yield takeEvery(GET_OVERALL_CATEGORY, getTheOverallCategory);
}

export default InspectContentSaga;