import React from 'react';
import ChartWrapper from '../Chart';
import { renderNoData, renderSingleValue } from '../utils';
import { QueryRenderer } from "@cubejs-client/react";
import { Link } from 'react-router-dom';
import { handleLowPercentage, pathWithPermission, percentageCalculation, totalCount } from '../../utilities';
import { viewDataMap } from '../../../../redux/constants/constants';
export function heatMapLegends(cubejsApi, name, query, renderToggler, dashboardWidget) {
  const queryCountPublicFiles = query[1];
  const queryCountAllFiles = query.length > 2 ? query[2] : null; // not available for all the widgets
  query = query[0];
  const returnRedirectTo = (name) => {
    let data = {
      "Sensitivity File Count":{
        pathname: "/tenant/dataMap",
        permission: [viewDataMap],
      },
      "Public Access Sensitivity File Count":{
        pathname: "/tenant/dataMap",
        permission: [viewDataMap],
      },
    }
    return pathWithPermission(data[name])
  };
  const renderTotalCount = (query, cubejsApi) => {
      return (
        <div className="widget-header2">
          <span className="span-total-count">
            <QueryRenderer
              query={query}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) {
                  return <div className="loader" />;
                }
                return renderSingleValue(resultSet, query.measures[0]);
              }}
            />
          </span>
        </div>
      );
    };
  const renderChart = (resultSet) => {
    const categories = {
      'HIGH': {
        color: 'high',
        displayName: 'High',
        count: 0,
        percentage: 0,
        unRoundPercentage: 0,
      },'MEDIUM': {
        color: 'medium',
        displayName: 'Medium',
        count: 0,
        percentage: 0,
        unRoundPercentage: 0,
      },'LOW': {
        color: 'low',
        displayName: 'Low',
        count: 0,
        percentage: 0,
        unRoundPercentage: 0,
      },'NOT_PROCESSED': {
        color: 'nonClassified',
        displayName: 'Non Classified',
        count: 0,
        percentage: 0,
        unRoundPercentage: 0,
      }
    };
    const data = resultSet.loadResponse.results[0].data;
    if (!data || data.length === 0) {
      return renderNoData('30%');
    }
    for (const [key, value] of Object.entries(categories)) {
      const d = data.find((d) => d[query.dimensions[0]] === key);
      value['count'] = parseInt(d ? d[query.measures[0]] : 0);
    }
    const valuesArray = Object.values(categories);
    let total_sensitivity_file_count = totalCount(valuesArray);
    if (total_sensitivity_file_count !== 0) {
      const floorValues = percentageCalculation(valuesArray, total_sensitivity_file_count);
      Object.values(categories).forEach((d) => {
        const floorValue = floorValues.find((val) => val.color === d.color);
        d.percentage = floorValue.count;
        d.unRoundPercentage = floorValue.unRoundPercentage;
      });   
    }
    const renderTotalOutOf = () => {
      return (
        <div className='out-of-container'>
          {renderTotalCount(queryCountPublicFiles, cubejsApi)}
          <span>/</span>
          {renderTotalCount(queryCountAllFiles, cubejsApi)}
        </div>
      );
    }
    //To not render the bar if the percentage is zero
    const renderBorderClassName = (percentage) => {
      if (percentage === 0) {
        return 'no-border';
      } else {
        return '';
      }
    };
    return (
      <React.Fragment>
        {
          queryCountAllFiles ? (
            renderTotalOutOf()
           ) : (
            renderTotalCount(queryCountPublicFiles, cubejsApi)
          )
        }
        <hr className='separatorStyle'/>
        <div className="sensitiveLevelWidget">
          <div className="view-details-header">
          </div>
          <div className='sensitivity-file-count'>
          {Object.keys(categories).map((key) => {
            const e = categories[key];
            return (
              <>
                <div className="heatMapLegenedContainer">
                  <div className="heatMapLegendCount">
                    <div className="heatMapLegend">{e.displayName}</div>
                  </div>
                  <div>{e.count}</div>
                </div>
                <div className="indicator-container">
                  <div className="bar-background">
                    <div
                      className={`bar ${e.color} ${renderBorderClassName(e.percentage)}`}
                      style={{ width: handleLowPercentage(e.count, e.percentage, '1%')}}
                      title={`${e.unRoundPercentage}%`}
                    ></div>
                  </div>
                  <div title={`${e.unRoundPercentage}%`}>{handleLowPercentage(e.count, e.percentage, '<1%')}</div>
                </div>
              </>
            );
          })}
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <ChartWrapper
      cubejsApi={cubejsApi}
      title={name}
      query={query}
      render={renderChart}
      redirectTo={returnRedirectTo(name)}
      renderToggler={renderToggler}
      dashboardWidget={dashboardWidget}
    />
  )
}